import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import CascadeLink from '../components/CascadeLink'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import { themeStyles, colors } from '../utils/theme'
import _ from 'lodash'


const styles = {
  featuredImage: {
    maxWidth: '230px',
    width: '100%',
  },
  employeeContainer: {
    width: '230px',
    margin: '40px',
    marginBottom: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  name: {
    color: colors.blueGreen,
    fontWeight: 600,
    textDecoration: 'none',
  },
  title: {
    marginTop: '10px',
    color: colors.grey,
    textDecoration: 'none',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    maxWidth: '930px',
    margin: '0 auto',
  },
  mainTitle: {
    textAlign: 'center',
  },
  spacer: {
    width: '100%',
    maxWidth: '500px',
    margin: '30px auto',
    height: '1px',
    backgroundColor: colors.lightGrey,
  }
}

const PostThumbnail = ({ post }) => (
  <div key={post.id}>
    <CascadeLink
      to={post.fields.slug}
      css={{ textDecoration: 'none' }}
      analyticsTargetName='Employee Thumbnail'
    >
      <div css={styles.employeeContainer}>
        {post.frontmatter.featuredImage ? (
          <div css={styles.featuredImage}>
            <PreviewCompatibleImage
              imageInfo={{
                image: post.frontmatter.featuredImage,
                alt: `featured image thumbnail for post ${post.frontmatter.title}`,
              }}
            />
          </div>
        ) : null}
        <p css={styles.title}>
          <span css={styles.name}>
            {post.frontmatter.name}
          </span>
          <br />
          {post.frontmatter.title}
          {post.frontmatter.nmls &&
            <React.Fragment>
              <br />
              NMLS ID # {post.frontmatter.nmls}
            </React.Fragment>
          }
        </p>
      </div>
    </CascadeLink>
  </div>
)


class EmployeeRoll extends React.Component {
  render() {
    const { data } = this.props
    const { edges } = data.allMarkdownRemark
    const employees = []
    const advisors = []
    edges.forEach(edge => {
      if (_.includes(edge.node.frontmatter.title, 'Adviser')) {
        advisors.push(edge.node)
      } else {
        employees.push(edge.node)
      }
    })

    return (
      <div>
        <h2 css={themeStyles.sectionHeader}>
          Our Leadership Team
        </h2>
        <div css={styles.rowContainer}>
          {employees && employees.map((post, i) => <PostThumbnail post={post} key={i} />)}
        </div>
        <div css={styles.spacer}/>
        <div css={[styles.rowContainer, {justifyContent: 'flex-start'}]}>
          {advisors && advisors.map((post, i) => <PostThumbnail post={post} key={i} />)}
        </div>
      </div>
    )
  }
}

EmployeeRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query EmployeeRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "employee-page" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                name
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredImage {
                  childImageSharp {
                    gatsbyImageData(width: 250, quality: 100, layout: CONSTRAINED)
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <EmployeeRoll data={data} count={count} />}
  />
)
