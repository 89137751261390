import React from 'react'
import { themeStyles, colors, presets } from '../utils/theme'
import branson from '../img/about-us/Branson.png'
import quotationMark from '../img/icons/quotationmark.svg'
import { FaCaretUp } from 'react-icons/fa';

const styles = {
  outer: {
    ...themeStyles.contentWidth,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column-reverse',
    [presets.Tablet]: {
      flexDirection: 'row',
      alignItems: 'flex-start',
    }
  },
  quoteOuter: {
    backgroundColor: colors.tan,
    borderRadius: '10px',
    padding: '20px 70px',
    position: 'relative',
    margin: '20px',
    marginTop: '0px',
    width: '100%',
    maxWidth: '450px',
    color: colors.grey,
    ':after': {
      content: '',
      position: 'absolute',
      top: 0,
      left: '50%',
      width: 0,
      height: 0,
      border: '35px solid transparent',
      borderBottomColor: '#00aabb',
      borderTop: 0,
      borderleft: 0,
      marginleft: '-17.5px',
      marginTop: '-35px',
    },
    [presets.Tablet]: {
      marginTop: '30px',
    }
  },
  photoOuter: {
    zIndex: 3,
    margin: 0,
    marginBottom: '30px',
    [presets.Tablet]: {
      margin: 0,
      marginLeft: '-50px',
    }
  },
  image: {
    maxWidth: '300px',
    borderRadius: '10px',
    [presets.Tablet]: {
      maxWidth: '450px',
    }
  },
  greenQuoteMark: {
    color: colors.green,
    margin: 0,
    maxWidth: '40px',
  }
}

const PopQuote = () => {
  return (
    <div css={styles.outer}>
      <div css={styles.quoteOuter}>
        <FaCaretUp size="120px" color={colors.tan} css={{ position: 'absolute', top: '-70px', left: '100px'}}/>
        <img css={styles.greenQuoteMark} src={quotationMark} alt="quotation"/>
        <p css={{ fontStyle: 'italic'}}>
          I love to invest in companies that make people's lives better and LoanSnap is exactly that. Its technology allows consumers to take control of their financial lives and will shake up the mortgage market.
          The strong founding team combined with its tech and a dedication to helping consumers will be a winning formula.
        </p>
        <p>
          <span css={{ fontWeight: 600 }}>Sir Richard Branson</span>
          <br/>
          Founder at Richard Branson's Virgin Group
        </p>
      </div>
      <div css={styles.photoOuter}>
        <img src={branson} css={styles.image} alt='Richard Branson'/>
      </div>
    </div>
  )
}

export default PopQuote
