import React from 'react'
import { themeStyles, colors, presets } from '../utils/theme'
import heroArrow from '../img/arrowtip_grey.svg'


const styles = {
  outer: {
    backgroundColor: colors.green,
    display: 'flex',
    flexDirection: 'column',
    height: '200px',
    position: 'relative',
  },
  grey: {
    display: 'flex',
    flex: .7,
    backgroundColor: colors.grey,
  },
  inner: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    ...themeStyles.contentWidth,
    ...themeStyles.textPadding,
    [presets.Tablet]: {
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
    zIndex: 2,
  },
  arrow: {
    height: '200px',
  },
  icon: {
    maxWidth: '200px',
    marginLeft: '20px',
    marginBottom: 0,
    marginTop: '40px',
    [presets.Mobile]: {
      marginTop: 0
    },
  },
  missionText: {
    color: colors.white,
    maxWidth: '400px',
    textAlign: 'center',
    fontWeight: 'normal',
  },
  background: {
    display: 'none',
    flexDirection: 'row',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    [presets.Tablet]: {
      display: 'flex',
    },
  },
  downloadText: {
    color: colors.grey,
    margin: 0,
    fontWeight: 'normal',
    marginBottom: '40px',
    marginLeft: '0px',
    [presets.Tablet]: {
      marginLeft: '100px',
      marginBottom: '0px',
      color: colors.green,
    },
    [presets.Desktop]: {
      marginLeft: '20px',
    }
  },
}

const OurMission = () => {
  return (
    <div css={styles.outer}>
      <div css={styles.background}>
        <div css={styles.grey} />
        <img src={heroArrow} css={styles.arrow} alt=''/>
        <div css={{display: 'flex', flex: 1}}/>
      </div>
      <div css={styles.inner}>
        <h2 css={styles.downloadText}>
          Our Mission
        </h2>
        <h2 css={styles.missionText} >
          Improve every American's financial situation
        </h2>
      </div>
    </div>
  )
}

export default OurMission
